<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import { toRef } from 'vue';

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
    },
    tag: {
        type: String,
        default: 'Link' // A
    }
});

const classes = computed(() =>
    props.active
        ? 'block w-full pl-3 pr-4 py-2 border-l-4 border-indigo-400 dark:border-indigo-600 text-left text-base font-medium text-indigo-700 dark:text-indigo-300 bg-indigo-50 dark:bg-indigo-900/50 focus:outline-none focus:text-indigo-800 dark:focus:text-indigo-200 focus:bg-indigo-100 dark:focus:bg-indigo-900 focus:border-indigo-700 dark:focus:border-indigo-300 transition duration-150 ease-in-out  whitespace-nowrap'
        : 'block w-full pl-3 pr-4 py-2 border-l-4 border-transparent text-left text-base font-medium text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 dark:hover:border-gray-600 focus:outline-none focus:text-gray-800 dark:focus:text-gray-200 focus:bg-gray-50 dark:focus:bg-gray-700 focus:border-gray-300 dark:focus:border-gray-600 transition duration-150 ease-in-out whitespace-nowrap'
);

// classes.value = 'block w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-[#273E44]/50 focus:outline-none focus:bg-gray-100 dark:focus:bg-[#273E44]/50 transition duration-150 ease-in-out';

</script>

<template>
    <Link v-if="tag == 'Link'" :href="href" :class="classes">
        <slot />
    </Link>
    <a v-else-if="!tag || tag == 'a'" :href="href" :class="classes">
        <slot />
    </a>
</template>
